import { Button } from "primereact";
import { useDispatch, useSelector } from "react-redux";
import { useWeb3ModalProvider, useWeb3ModalAccount, useWeb3Modal } from "@web3modal/ethers5/react";
import { ethers } from "ethers";
import { useEffect } from "react";

const ConnectWallet = ({ children }) => {
	const { polygonChainId, userDetails } = useSelector((state) => state.auth);
	const { walletProvider } = useWeb3ModalProvider();
	const { address, chainId } = useWeb3ModalAccount();
	const { open } = useWeb3Modal();
	// const { disconnect } = useDisconnect()
	const dispatch = useDispatch();

	useEffect(() => {
		if (walletProvider) {
			const web3Provider = new ethers.providers.Web3Provider(walletProvider);
			dispatch({ type: "SET_WEB3_PROVIDER", payload: web3Provider });
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (address && walletProvider) {
			const web3Provider = new ethers.providers.Web3Provider(walletProvider);
			dispatch({ type: "SET_WEB3_PROVIDER", payload: web3Provider });
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [address, walletProvider]);

	const splitWalletAddress = (walletAddress) => {
		if (walletAddress) {
			return walletAddress.substring(0, 6) + "..." + walletAddress.substring(walletAddress.length - 4, walletAddress.length);
		}
	};

	return (
		<>
			{address ? (
				<>
					{chainId !== polygonChainId ? (
						<>
							<Button className="p-button-danger w-full mt-3" onClick={() => open({ view: "Networks" })} label="Change Network" />
						</>
					) : (
						<>
							{address?.toLocaleLowerCase() !== userDetails?.ethAddress ? (
								<>
									<Button disabled className="p-button-danger w-full mt-3" label={`Please connect to ${splitWalletAddress(userDetails?.ethAddress)} wallet`} />
								</>
							) : (
								children
							)}
						</>
					)}
				</>
			) : (
				<Button className="w-full mt-3" onClick={() => open()} label="Connect Wallet" />
			)}
		</>
	);
};
export default ConnectWallet;
